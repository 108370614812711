import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Flex from '../components/styled/base/Flex';
import Box from '../components/styled/base/Box';
import Content from '../components/styled/Content';

const Img = styled.img`
  object-fit: cover;
  height: 400px;
  width: 100%;
`;

const Page = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.page.title} />
      <Flex
        position="sticky"
        top="92px"
        flexDirection={['column', null, null, 'row-reverse']}
        justifyContent="center"
        mb="5"
      >
        <Box width={[1, null, null, '60%']}>
          {data.page.image && (
            <Img src={data.page.image.fluid.src} alt={data.page.title} />
          )}
          <Content
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(data.page.content.json),
            }}
          />
        </Box>
      </Flex>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      slug
      title
      image {
        fluid(cropFocus: CENTER, resizingBehavior: FILL, quality: 100) {
          src
        }
      }
      content {
        json
      }
    }
  }
`;
